import React, { useState } from "react";
import { motion } from "framer-motion";

function Contact() {
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormState({
      ...formState,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("/api/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formState),
      });

      if (response.ok) {
        alert("Message sent successfully!");
        setFormState({ name: "", email: "", message: "" });
      } else {
        alert("Failed to send message. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again.");
    }
  };

  const handleEmailClick = (e) => {
    e.preventDefault();
    const email = "dev.derekm@gmail.com";
    const subject = "Inquiry from Website";
    const body =
      "Hello Derek,\n\nI visited your website and would like to ask you...";

    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    window.location.href = mailtoLink;
  };

  return (
    <div className="d-flex flex-column min-vh-100 justify-content-center pt-16">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.2 }}
      >
        <div className="contentOuter ">
          <div className="contentInner row g-4 ">

            <div className="col-12 col-md-6">
              <div className="contentCard d-flex flex-column">
                <h3
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  Whatsapp
                </h3>
                <div className="row h-100 gap-4">
                  <div className="col-12 d-flex flex-column ">
                    <h5 style={{ textAlign: "center" }}>Open</h5>
                    <div
                      className="qr-code-container d-flex align-items-center justify-content-center"
                      style={{ borderRadius: "10px", height: "100%" }}
                    >
                      <a
                        href="https://wa.me/14806623587"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="contact-button"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <lord-icon
                          src="https://cdn.lordicon.com/cywksamr.json"
                          trigger="hover"
                          stroke="bold"
                          colors="primary:#2f709e,secondary:#333"
                          target=".contentCard"
                          style={{
                            width: "100%",
                            height: "100%",
                            minWidth: "2rem",
                            minHeight: "2rem",
                          }}
                        ></lord-icon>
                      </a>
                    </div>
                  </div>
                  <div className="col-12 d-flex flex-column ">
                            <h5 style={{ textAlign: "center" }}>- OR -</h5>
                            </div>
                          <div className="col-12 d-flex flex-column ">
                            <h5 style={{ textAlign: "center" }}>Scan QR</h5>
                            <div
                              className="qr-code-container  d-flex align-items-center justify-content-center"
                              style={{ borderRadius: "10px" }}
                            >
                              <img
                                src="/WhatsappQR.png"
                                alt="WhatsApp QR Code"
                                style={{
                                  maxWidth: "200px",
                                  maxHeight: "200px",
                                  objectFit: "contain",
                                  borderRadius: "10px",
                                }}
                              />
                            </div>
                          </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="contentCard d-flex flex-column">
                <h3
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  Email
                </h3>
                <div className="row h-100 gap-4">
                 
                  <div className="col-12 d-flex flex-column ">
                    <h5 style={{ textAlign: "center" }}>Open</h5>
                    <div
                      className="qr-code-container d-flex align-items-center justify-content-center"
                      style={{ borderRadius: "10px", height: "100%" }}
                    >
                      <a
                        onClick={handleEmailClick}
                        className="contact-button"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                      >
                        <lord-icon
                          src="https://cdn.lordicon.com/diihvcfp.json"
                          trigger="hover"
                          stroke="bold"
                          colors="primary:#2f709e,secondary:#333"
                          target=".contentCard"
                          style={{
                            width: "100%",
                            height: "100%",
                            minWidth: "2rem",
                            minHeight: "2rem",
                          }}
                        ></lord-icon>
                      </a>
                    </div>
                  </div>
                  <div className="col-12 d-flex flex-column ">
                            <h5 style={{ textAlign: "center" }}>- OR -</h5>
                            </div>
                  <div className="col-12 d-flex flex-column ">
                    <h5 style={{ textAlign: "center" }}>Scan QR</h5>
                    <div
                      className="qr-code-container  d-flex align-items-center justify-content-center"
                      style={{ borderRadius: "10px" }}
                    >
                      <img
                        src="/EmailQR.png"
                        alt="WhatsApp QR Code"
                        style={{
                          maxWidth: "200px",
                          maxHeight: "200px",
                          objectFit: "contain",
                          borderRadius: "10px",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          
            {/* 
        <div className="container mt-5">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <form className="contact-form glass p-4" onSubmit={handleSubmit}>
                <h1>Contact Derek</h1>
                <p>Contact methods and information</p>
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">Name</label>
                  <input type="text" className="form-control" id="name" value={formState.name} onChange={handleChange} placeholder="Your Name" required />
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">Email</label>
                  <input type="email" className="form-control" id="email" value={formState.email} onChange={handleChange} placeholder="your.email@example.com" required />
                </div>
                <div className="mb-3">
                  <label htmlFor="message" className="form-label">Message</label>
                  <textarea className="form-control" id="message" rows="5" value={formState.message} onChange={handleChange} placeholder="Your message here..." required></textarea>
                </div>
                <button type="submit" className="content-button" disabled={!formState.name || !formState.email || !formState.message}>Send Message</button>
              </form>
            </div>
          </div>
        </div> */}
          </div>
        </div>
      </motion.div>
    </div>
  );
}

export default Contact;
