import React from "react";
import { FaLinkedin, FaInstagram, FaFacebookF, FaGithub } from "react-icons/fa";

const Socials = () => {
  return (
    <ul className="social-links ">
      <li>
        <a
          href="https://wa.me/14806623587"
          target="_blank"
          className="social-button"
        >
          <lord-icon
            src="https://cdn.lordicon.com/cywksamr.json"
            trigger="hover"
            stroke="bold"
            colors="primary:#19578a,secondary:#333"
            target=".contentCard"
          ></lord-icon>
        </a>
      </li>

      <li>
        <a
          href="https://www.linkedin.com/in/dev-derekm/"
          target="_blank"
          className="social-button"
        >
          <lord-icon
            src="https://cdn.lordicon.com/mdyiqybm.json"
            trigger="hover"
            stroke="bold"
            colors="primary:#19578a,secondary:#333"
            target=".contentCard"
          ></lord-icon>
        </a>
      </li>

      {/* <li>
        <a href="#" className="social-button">
          <FaInstagram />
        </a>
      </li>
       */}

      {/* <li>
        <a href="https://github.com/DerekM84" target="_blank" className="social-button">
      
          <FaGithub />
        </a>
      </li> */}

      {/* <li>
        <a
          href="https://www.facebook.com/derek.miller.9404/"
          target="_blank"
          className="social-button"
        >
          <lord-icon
            src="https://cdn.lordicon.com/iqagrlso.json"
            trigger="hover"
            stroke="bold"
            colors="primary:#19578a,secondary:#333"
            target=".contentCard"
          ></lord-icon>
        </a>
      </li> */}
    </ul>
  );
};

export default Socials;
